<template>
  <HgJournawaySearch
    allowHotjarRecord
    :is-mobile="isMobile"
    :is-loading-destinations="$apollo.queries.journawayDestinationAutosuggest.loading"
    :destinations="destinations"
    :ibe-url="searchUrl"
    @onUpdateDestinationTerm="updateDestinationTerm"
    @onUpdateSearchParameters="updateSearchParameters"
    @onTriggerSearch="triggerSearch"
  />
</template>

<script>
import { mapState } from 'vuex';
import { format, differenceInDays, addDays } from 'date-fns';
import journawayDestinationAutosuggest from '@/apollo/queries/ibe/journawayDestinationAutosuggest';
import journawaySearchUrl from '@/apollo/queries/ibe/journawaySearchUrl';

import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  mixins: [GtmEventsMixin],

  data: () => ({
    destinationTerm: '',
    destinations: [],
    autosuggestAmadeus: [],
    searchUrl: '',
    destination: null,
    duration: null,
    hasParams: false,
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  methods: {
    updateDestinationTerm(term) {
      this.destinationTerm = term;
    },

    updateSearchParameters(params) {
      const {
        destination,
        dateRange,
        duration,
      } = params;

      this.destination = destination;
      this.dateRange = dateRange;
      this.duration = duration || '';
      this.hasParams = true;

      this.$apollo.queries.journawaySearchUrl.refresh()
    },

    triggerSearch() {
      let clickedElement = 'none';

      if (
        this.destination &&
        this.destination.name &&
        this.destination.default
      ) {
        clickedElement = 'dropdown';
      }
      if (
        this.destination &&
        this.destination.name &&
        !this.destination.default
      ) {
        clickedElement = 'autosuggest';
      }

      var duration;

      // Reisedauer für alle Varianten, außer beliebig, exakt, 1 Woche, 2 Wochen um je 1 erhöhen (NUR DE & AT)
      if (
        ['de', 'at'].includes(this.$i18n.locale) &&
        !['', 'exact', '7', '14'].includes(this.duration)
      ) {
        duration = this.duration
          .split(',')
          .map((el) => parseFloat(el) + 1)
          .join(',');
      } else {
        duration = this.duration;
      }

      this.$gtm.push({
        event: 'journaway_search_used',
        clickedElement,
        destination:
          this.destination && this.destination.name
            ? this.destination.name
            : 'any',
        period: `${format(this.dateRange.start, 'EE, dd.MM.yyyy')} - ${format(
          this.dateRange.end,
          'EE, dd.MM.yyyy'
        )}`,
        travelDuration: duration,
      });
    },
  },

  apollo: {
    journawayDestinationAutosuggest: {
      query: journawayDestinationAutosuggest,
      debounce: 300,
      skip() {
        return this.destinationTerm === '';
      },
      variables() {
        return {
          query: this.destinationTerm
        };
      },
      result({ data }) {
        this.destinations = data.journawayDestinationAutosuggest
      }
    },
    journawaySearchUrl: {
      query: journawaySearchUrl,
      skip() {
        return this.hasParams === false;
      },
      variables() {
        let durationFrom = null
        let durationTo = null
        let destination = null

        if (this.duration != null) {
          if (this.duration == 'exact') {
            if (this.dateRange != null) {
              let numberOfDays = differenceInDays(this.dateRange.end, this.dateRange.start)
              durationFrom = numberOfDays
              durationTo = numberOfDays
            }
          } else if (this.duration.includes(",")) {
            durationFrom = Number(this.duration.split(",")[0]) + 1
            durationTo = Number(this.duration.split(",")[1]) + 1
          } else if (this.duration.length > 0) {
            if (this.duration == "7" || this.duration == "14") {
              durationTo = Number(this.duration)
              durationFrom = Number(this.duration)
            } else {
              durationTo = Number(this.duration) + 1
              durationFrom = Number(this.duration) + 1
            }
          }
        }

        if (this.destination?.id != null & this.destination?.name != null) {
          destination = {
            id: this.destination.id,
            name: this.destination.name
          }
        }

        let startDate = this.dateRange?.start
        let endDate = this.dateRange?.end

        if (startDate != null) {
          startDate = addDays(startDate, 1)
        }

        if (endDate != null) {
          endDate = addDays(endDate, 1)
        }

        return {
          input: {
            destination,
            durationTo,
            durationFrom,
            startDate,
            endDate
          }
        };
      },
      result({ data }) {
        this.searchUrl = data.journawaySearchUrl
      }
    }
  }
};
</script>
