<template>
  <HgHeaderBackground
    :image="$t(`homeHero.imageArray.${this.imageNumber}`)"
    :svg-language="$t('homeHeroSVG')"
    :showArrow="true"
    :isNewDesign="true"
  >
    <TravelAgentRegister
      v-if="$i18n.locale === 'de'"
      class="badge-in-search"
      :show-moving-badge="showMovingBadge"
    />
    <!-- DE -->
    <HgSearchTabs
      v-if="['at', 'ch', 'de'].includes($i18n.locale)"
      :links="$t('searchCustomLinks')"
      :starting-index="startingIndex"
      :panel-switched="true"
      :activeTab="activeTab"
      @onLinkout="linkoutClicked"
    >
      <template :slot="$t('ibeSearch.title')">
        <AmadeusPackageTourSearch />
      </template>
      <template :slot="$t('accommodation.accomodationbutton')">
        <AccommodationSearchVacationRental />
      </template>
      <template :slot="$t('accommodation.hotelbutton')">
        <AccommodationSearchHotel />
      </template>
      <template :slot="'Kurzreisen'">
        <ShortTripSearch />
      </template>
      <template :slot="$t('skiSearch.title')">
        <SkiSearch />
      </template>
      <template
        :slot="'Kreuzfahrten'"
        v-if="['at', 'de'].includes($i18n.locale)"
      >
        <CruiseCompassSearch />
      </template>
      <template
        :slot="'Rundreisen'"
        v-if="['at', 'de'].includes($i18n.locale)"
      >
        <JournawaySearch />
      </template>
      <template :slot="$t('deals')">
        <DealSearch />
      </template>
    </HgSearchTabs>

    <!-- NL -->
    <HgSearchTabs
      v-else-if="$i18n.locale === 'nl'"
      :starting-index="startingIndex"
      :links="$t('searchCustomLinks')"
      @onLinkout="linkoutClicked"
    >
      <template slot="Pakketreizen">
        <NetmatchPackageTourSearch />
      </template>

      <template slot="Hotels">
        <NetmatchHotelTourSearch />
      </template>

      <!--      <template :slot="$t('deals')">-->
      <!--        <DealSearch />-->
      <!--      </template>-->

      <template :slot="$t('accommodation.accomodationbutton')">
        <AccommodationSearchVacationRental />
      </template>

      <template :slot="$t('skiSearch.title')">
        <SkiSearch />
      </template>

      <!-- <template :slot="$t('accommodation.tabname')">
        <HoliduSearch />
      </template> -->
    </HgSearchTabs>

    <!-- ES -->
    <HgSearchTabs
      v-else-if="$i18n.locale === 'es'"
      :starting-index="startingIndex"
      :links="$t('searchCustomLinks')"
      @onLinkout="linkoutClicked"
    >
      <template slot="Vuelos & Hotel">
        <LogitravelPackageTourSearch />
      </template>

      <template :slot="$t('deals')">
        <DealSearch />
      </template>

      <template :slot="$t('accommodation.accomodationbutton')">
        <AccommodationSearchVacationRental />
      </template>

      <!--
      <template :slot="$t('accommodation.tabname')">
        <HoliduSearch />
      </template>
      -->
    </HgSearchTabs>
  </HgHeaderBackground>
</template>
<script>
import { mapState } from 'vuex';
import { isBefore, isAfter, addDays } from 'date-fns';
import NetmatchPackageTourSearch from './NetmatchPackageTourSearch.vue';
import NetmatchHotelTourSearch from './NetmatchHotelTourSearch.vue';
import CruiseCompassSearch from './CruiseCompassSearch.vue';
import JournawaySearch from './JournawaySearch.vue';

export default {
  components: {
    TravelAgentRegister: () =>
      import('@/components/partials/TravelAgentRegister'),
    AmadeusPackageTourSearch: () =>
      import('@/components/search/AmadeusPackageTourSearch'),
    DealSearch: () => import('@/components/search/DealSearch'),
    SkiSearch: () => import('@/components/search/SkiSearch'),
    ShortTripSearch: () => import('@/components/search/ShortTripSearch'),
    AccommodationSearch: () => import('@/components/search/AccomodationSearch'),
    AccommodationSearchHotel: () =>
      import('~/components/search/AccommodationSearchHotel'),
    AccommodationSearchVacationRental: () =>
      import('~/components/search/AccommodationSearchVacationRental'),
    HoliduSearch: () => import('@/components/search/HoliduSearch'),
    VakantiediscounterPackageTourSearch: () =>
      import('@/components/search/VakantiediscounterPackageTourSearch'),
    LogitravelPackageTourSearch: () =>
      import('@/components/search/LogitravelPackageTourSearch'),
    NetmatchPackageTourSearch,
    NetmatchHotelTourSearch,
    CruiseCompassSearch,
    JournawaySearch
  },
  props: {
    showMovingBadge: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    startingIndex: 0,
    searchTabsComponentKey: 0,
    date: new Date()
  }),

  computed: {
    ...mapState({
      imageNumber: (state) => state.imageNumber,
      experiments: (state) => state.experiments
    }),
    customLinks() {
      const links = this.$i18n.t('searchCustomLinks');
      if (Array.isArray(links) && links.length > 0) return links;

      return null;
    }
  },

  watch: {
    '$route.path': 'checkRoute'
  },
  created() {
    this.checkRoute();
  },

  methods: {
    linkoutClicked(tab) {
      this.$gtm.push({
        event: 'search_tab_linkout_clicked',
        link_name: tab.link.label
      });
    },
    shortTripsSeason() {
      const season = {
        start: 2, // 2 == march
        end: 8 // 8 == september
      };

      const date = new Date();

      return (
        isAfter(
          date,
          addDays(new Date(this.date.getFullYear(), season.start, 1), -1)
        ) && isBefore(date, new Date(this.date.getFullYear(), season.end, 1))
      );
    },
    checkRoute() {
      if (['ch', 'de', 'at'].includes(this.$i18n.locale)) {
        if (this.$route.path === '/suchen-und-buchen/') {
          this.startingIndex = 1;
          this.activeTab = 1;
        } else {
          this.startingIndex = 0;
          this.activeTab = 0;
        }
        this.searchTabsComponentKey += 1;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.search-tabs-container {
  background-color: $teal-60;
  border-radius: 0 0 $radius-large $radius-large;
  position: relative;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.badge-in-search {
  position: absolute;
  top: rem(92);
}

@media screen and (min-width: 768px) {
  .search-tabs-container {
    padding: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .hg-search-tabs {
    max-width: 1240px;
  }

  .badge-in-search {
    top: rem(124);
  }
}
</style>

<style lang="scss">
.hg-search-tabs .buttons:not(:last-child) {
  margin-bottom: 0;
}

.hg-search-tabs {
  width: 100%;
  position: relative;
  z-index: 20;
}
</style>
