<template>
  <HgCruiseSearch
    allowHotjarRecord
    :is-mobile="isMobile"
    :is-loading-destinations="isLoading"
    :cruiseLines="cruiseLines"
    :search-url="searchUrl"
    :lang="lang"
    :regions="regions"
    @onUpdateSearchParameters="updateSearchParameters"
    @onTriggerSearch="triggerSearch"
  />
</template>

<script>
import { mapState } from 'vuex';
import CruiseCompassCruiseLinesQuery from '@/apollo/queries/cruise-compass/CruiseCompassCruiseLines.gql';
import CruiseCompassRegionsQuery from '@/apollo/queries/cruise-compass/CruiseCompassRegions.gql';
import CruiseCompassSearchInput from '@/apollo/queries/cruise-compass/CruiseCompassSearchInput.gql';

export default {
  data: () => ({
    destinationTerm: '',
    isLoading: false,
    suggestedDestinations: [],
    searchUrl: '',
    orderedDestinations: [],
    lang: {},
    cruiseLines: [],
    regions: [],
    serachData: {
      dateRange: null,
      region: null,
      cruiseLine: null,
      travelers: { adults: null, ages: [] }
    }
  }),

  created() {
    this.lang = {
      cruiseLineLabel: this.$i18n.t('cruiseCompass.cruiseLineOverview'),
      cruiseLinePlaceholder: this.$i18n.t('cruiseCompass.placeholder'),
      regionLabel: this.$i18n.t('cruiseCompass.regionsOverview'),
      regionPlaceholder: this.$i18n.t('cruiseCompass.placeholder')
    };
    this.getCruiseLines();
    this.getRegions();
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  methods: {
    updateSearchParameters(data) {
      this.serachData = data;
      this.getUrl(data);
    },

    triggerSearch() {
      const { dateRange, region, cruiseLine, travelers } = this.serachData;

      const pushdata = {
        regions: region?.map((reg) => reg.name),
        period: `${this.$dateFns.format(
          dateRange.start,
          'EEE dd.MM.yyyy'
        )} - ${this.$dateFns.format(dateRange.end, 'EEE dd.MM.yyyy')}`,
        cruiseLines: cruiseLine?.map((line) => line.name),
        travellers: {
          adults: travelers?.adults,
          children: travelers?.ages.length,
          ages: travelers?.ages
        }
      };

      this.$gtm.push({ ...pushdata, event: 'cruise_compass_search_used' });
    },

    debounce(func, delay) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(func, delay);
    },
    async getCruiseLines() {
      try {
        const {
          data: { cruiseCompassCruiseLines }
        } = await this.$apollo.query({
          query: CruiseCompassCruiseLinesQuery
        });

        this.cruiseLines = cruiseCompassCruiseLines.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (e) {
        console.log('error', e.graphQLErrors);
      }
    },
    async getRegions() {
      try {
        const {
          data: { cruiseCompassRegions }
        } = await this.$apollo.query({
          query: CruiseCompassRegionsQuery,
          variables: {
            query: ''
          }
        });

        this.regions = cruiseCompassRegions.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (e) {
        console.log('error', e.graphQLErrors);
      }
    },
    async getUrl(data) {
      const { dateRange, region, cruiseLine, travelers } = data;

      const input = {
        agesOfChildren: travelers?.ages,
        cruiseLineIds: cruiseLine?.map((line) => line.id),
        startDate: this.$dateFns.formatISO(
          this.$dateFns.addDays(dateRange.start, 1)
        ),
        endDate: this.$dateFns.formatISO(
          this.$dateFns.addDays(dateRange.end, 1)
        ),
        numberOfAdults: travelers?.adults,
        regionIds: region?.map((reg) => reg.id)
      };

      try {
        const {
          data: { cruiseCompassSearchUrl }
        } = await this.$apollo.query({
          query: CruiseCompassSearchInput,
          variables: {
            input
          }
        });

        this.searchUrl = cruiseCompassSearchUrl;
      } catch (e) {
        console.log('error', e.graphQLErrors);
      }
    }
  }
};
</script>
